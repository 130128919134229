import React, { createContext, useEffect, useState } from 'react'
import { isMobile } from './theme/common';

export const TogglePanelContext = createContext({
  isOpen: false,
  handleToggleOpen: async () => { },
  sendPostMessage: () => { }
})

const TogglePanelContextProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [isO, setIsO] = useState<boolean>(true);
  const handleToggleOpen = async () => {
    await setIsOpen(!isOpen);
    sendPostMessage()
  };
  const sendPostMessage = () => {
    var chatIcon = document.getElementById("ChatIcon")?.style;
    var ChatSpanel= document.getElementById("ChatSpanel")?.style;
    let right = "";
    let bottom = "";
    let Chatwidth= "";
    let Chatheight= "";
    setIsO(!isO);
    if (chatIcon && ChatSpanel) {
      if (isO == false) {

        if (isMobile()) {
          chatIcon.display = "none";
          right = "0px";
          bottom = "0px";
          Chatwidth= "100%";
          Chatheight="100%";
          
        } else if (navigator.userAgent.match(/(pad|iPad)/i)) {
          if (window.orientation == 0 || window.orientation == 180) {
            chatIcon.display = "none";
            right = "0px";
            bottom = "0px";
            Chatwidth= "100%";
            Chatheight="100%";
            
          }
        }
        else {
          chatIcon.display = "flex";
          right = "1rem";
          bottom = "5rem";
          Chatwidth= "94px";
          Chatheight="94px"; 
        }

      } else {
        chatIcon.display = "flex"; 
        right = "1rem";
        bottom = "5rem";
        Chatwidth= "94px";
        Chatheight="94px";
      }
    const getParamString =function(name){
        var reg= new RegExp("(^|&)" + name +"=([^&]*)(&|$)","i" );
        var r = window.location.search.substring(1).match(reg);
        if (r != null) {
          return decodeURIComponent(r[2]);
        }
        return null;
      }
      var pStr=getParamString("param")
      if (pStr!=null) {
        if (pStr.match("IRApp")) {
          chatIcon.display="none"
          ChatSpanel.display="block"
        }
      }
    }

    let height = 0;
    let width = 0;
    const srootEl = document.getElementById("sroot");
    if (
      srootEl &&
      (height !== srootEl.offsetHeight || width !== srootEl.offsetWidth)
    ) {
      height = srootEl.offsetHeight;
      width = srootEl.offsetWidth;
      window.parent.postMessage(
        {
          frameHeight: height,
          frameWidth: width,
          marginRight:right,
          marginBottom:bottom,
          Chatwidth : Chatwidth,
          Chatheight: Chatheight,
        },
        "*"
      );
      console.debug("height:", height, "width:", width);
    }
  };


  return <TogglePanelContext.Provider value={{ isOpen, handleToggleOpen, sendPostMessage }}>
    {children}
  </TogglePanelContext.Provider>
}

export default TogglePanelContextProvider
