import React, {  useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components/macro";
import { ReactComponent as SendIcon } from "../../assets/icons/paper-plane.svg";

interface InputBarProps {
  handleSend: (val: string) => void;
}
const InputBar: React.FC<InputBarProps> = ({ handleSend }) => {
  const inputRef = useRef<any>(null);
  const [inputVal, setInputVal] = useState("");

  //   autofocus
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const clearInputText = () => {
    setInputVal("");
  };
  const handleSendAndClear = () => {
    console.debug(`value: `, inputVal);
    if (inputVal) {
      handleSend(inputVal);
      clearInputText();
    }
  };
  const handleKeyPress = (e) => {
    // console.debug(`handleKeyPress`, e.key, "shiftKey", e.shiftKey);
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendAndClear();
    }
  };
  const canClickSend = useMemo(() => !!inputVal, [inputVal]);
  return (
    <SInputBar>
      <SInput
        value={inputVal}
        placeholder="Type a message"
        ref={inputRef}
        onKeyPress={handleKeyPress}
        onChange={(e) => setInputVal(e.target.value)}
        rows={1}
      />
      <SSendIcon
        className={canClickSend ? "clickable can-click" : ""}
        onClick={() => (canClickSend ? handleSendAndClear() : null)}
      />
    </SInputBar>
  );
};

export default InputBar;
const SInputBar = styled.div`
  border-top: solid 1px ${(props) => props.theme.palette.common.borderGrey};
  background: ${(props) => props.theme.palette.background.paper};
  border-radius: ${(props) =>
    `0 0 ${props.theme.borderRadius.chatPanel} ${props.theme.borderRadius.chatPanel}`};
  position: relative;
  display: flex;
  height: 72px;
  padding:  0 12px;
`;
const SInput = styled.textarea`
  
  resize: none;
  font-family: ${(props) => props.theme.typography.fontFamily.body};
  font-size: ${(props) => props.theme.typography.fontSize.medium};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: ${(props) => props.theme.palette.text.primary};
  border: olid 1px #d6d8da;
  outline: none;

  margin:  11px 16px 0;
  padding: 12px 57px 12px 16px;
  // padding-right: 3.5rem;
  border-radius: 26px;
  border: solid 1px #d6d8da;
  background: ${(props) => props.theme.palette.secondary.light};
  flex: 0.8;
  line-height: 1.5;
  letter-spacing: 0.1px;
  height: 24px;

`;
const SSendIcon = styled(SendIcon)`
  position: absolute;
  height: 48px;
  width: 48px;
  padding: 0 0;
  top: 20%;
  right: 1rem;
  &.can-click {
    & path {
      fill: ${(props) => props.theme.palette.secondary.light};
    }
    &:hover {
      transform: scale(1.2);
      transition: transform 0.1s linear;
    }
  }
  & path {
    fill: ${(props) => props.theme.palette.secondary.light};
  }
`;

