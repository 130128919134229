// import getPromiseMockedData from "../mocked-data/getPromiseMockedData"; // for mocked data
import getApiInstance from "./getApiInstance";
// import dispatcherMockedData from "../mocked-data/dispatcher.mocked-data"; // for mocked data

/*
GET /init
    - expected return array of messages, each of message have the structure below
        { type: 'TEXT', data: <string> }
POST /chat
    - params is { text: <string> }
    - expected return is array of messages, each of message have the structure below
        { type: 'TEXT' | 'OPTIONS' | 'CARDS' | 'TABLE_LINK',
          data: <data tha matches the type> }
        - TEXT
            data: <string>
        - OPTIONS
            data: {title: string, options: <string>[] or {label: string, value: string}[] }
                    (this second one is when wanted onclick to send different value from the label)
        - CARDS
            data: { title: string, options: <string>[]  or {label: string, value: string}[] }
        - TABLE_LINK
            data: string (the link to the table file)
*/

const postText = async (params: {text: string, sessionId:string}) => {
  const {text} = params
  if (!text) {
    console.warn(`text is empty so didn't call`);
    return;
  }
  return getApiInstance()
    .post("/chat", params)
    .then((res) => {
      return !Array.isArray(res.data) ? [res.data] : res.data;
    });
  // return getPromiseMockedData(dispatcherMockedData.getPostTextRes(text));
};

const init = async (params: { sessionId: string }) => {
  const {sessionId} = params
  return getApiInstance()
    .post("/init",{ sessionId })
    .then((res) => {
      return !Array.isArray(res.data) ? [res.data] : res.data;
    });
  // return getPromiseMockedData(dispatcherMockedData.initRes);
};


const dispatcherServices = {
  postText,
  init,
};

export default dispatcherServices;
