import React from "react";
import styled from "styled-components";
import Chat from "../../models/chat.models";
import { ReactComponent as TabletIcon } from "../../assets/icons/tablet.svg";

interface TableLinkCardProps {
  chatM: Chat;
}
const TableLinkCard: React.FC<TableLinkCardProps> = ({ chatM }) => {
  return (
    //   TODO:check if chatM.data is valid url
    <STableLinkCard href={chatM && chatM.data} target="_blank">
      <STabletIcon />
      <SText>View Report</SText>
    </STableLinkCard>
  );
};

export default TableLinkCard;
const STableLinkCard = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${(props) => `${props.theme.borderRadius.chatPanel}`};
  background: ${(props) => props.theme.palette.primary.dark};
  color: ${(props) => props.theme.palette.primary.contrastText};
  width: 150px;
  height: 130px;
`;
const STabletIcon = styled(TabletIcon)`
  width: 50px;
  height: 50px;
  & path {
    fill: ${(props) => props.theme.palette.primary.contrastText};
  }
  margin-bottom: 1rem;
`;
const SText = styled.div`
  font-family: ${(props) => props.theme.typography.fontFamily.body};
  font-size: ${(props) => props.theme.typography.fontSize.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
`;
