import React from "react";
import styled from "styled-components/macro";
import Chat from "../../models/chat.models";
import getTextToLinkDiv from "../../utils/getTextToLinkDiv";
import AiaAvatar from "./AiaAvatar";
import Cards from "./Cards";
import DotsWave from "./DotsWave";
import OptionsCard from "./OptionsCard";
import TableLinkCard from "./TableLinkCard";

interface ChatElProps {
  chatM: Chat;
  hideAvatar?: boolean; // for bot only
}


const ChatEl: React.FC<ChatElProps> = ({ chatM, hideAvatar = false }) => {
  const renderChatBubble = () => {
    if (chatM.isLoading()) {
      return (
        <SChatBubble>
          <DotsWave />
        </SChatBubble>
      );
    } else {
    if (chatM.isText()) {
        // TODO: parse link and replace with text
        return <SChatBubble>{getTextToLinkDiv(chatM.data)}</SChatBubble>;
      } else if (chatM.isCards()) {
        return <Cards chatM={chatM} />;
      } else if (chatM.isOptions()) {
        return <OptionsCard chatM={chatM} />;
      } else if (chatM.isTableLink()) {
        return <TableLinkCard chatM={chatM} />;
      } else if (chatM.isError()) {
        return (
          <SChatBubble >
          <i />
          Sorry the Chatbot cannot respond at this moment. Please try again later.
          <p hidden>{chatM.data}</p>
        </SChatBubble>
      );
    }

    console.error(`unsupport type :${chatM.type}`);
    return `unsupport type`;
  }
};
return (
  <SChatEl className={chatM.isFromBot() ? "from-bot" : "from-user"}>
    {chatM.isFromBot() && <AiaAvatar hiddenVisibility={hideAvatar} />}
    <SBubbleAndTime>
      {renderChatBubble()}
      {<STime>{chatM.getTime()}</STime>}
    </SBubbleAndTime>
  </SChatEl>
);
};

export default ChatEl;
const SChatBubble = styled.div.attrs((props) => ({
className: props.className,
}))`
max-width: 80%;
&.is-error {
  color: ${(props) => props.theme.palette.error.main} !important;
  & i {
    color: ${(props) => props.theme.palette.error.main};
    margin-right: 0.5rem;
  }
}
//box-shadow: 2px 2px 5px  rgba(0, 0, 0, 0.25);
box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.03);
line-height: 1.3;
padding: 15px 20px;
white-space: pre-wrap;
  word-break: break-word;
`;

const SBubbleAndTime = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SChatEl = styled.div.attrs((props) => ({
  className: props.className,
}))`
  display: flex;
  column-gap: 0.5rem;
  & + & {
    margin-top: 1rem;
  }
  &.from-bot {
    & ${SChatBubble} {
      border-radius: 0 10px 10px 10px;
      background: ${(props) => props.theme.palette.secondary.light};
      color: ${(props) => props.theme.palette.secondary.contrastText};
    }
    & ${SBubbleAndTime} {
      align-items: flex-start;
    }
  }
  &.from-user {
    justify-content: flex-end;
    & ${SChatBubble} {
      border-radius: 10px 10px 0 10px;
      /*background: ${(props) => props.theme.palette.primary.main};*/
      background: #be0f3e;
      color: ${(props) => props.theme.palette.primary.contrastText};
    }
    & ${SBubbleAndTime} {
      align-items: flex-end;
    }
  }
`;
const STime = styled.div`
  font-size: ${(props) => props.theme.typography.fontSize.small};
  /*color: ${(props) => props.theme.palette.text.secondary};*/
  color: #848A90;
  text-align: right;
  margin-top: 3px;
  margin-right: 3px;
`;
