
// Prefix text to appear when there is the link
const PREFIX_WHEN_IS_LINK = 'Please click '
// Text to appear when there is the link
const TEXT_WHEN_IS_LINK = 'here'

const htmlRegEx = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/gim;
const hasHttpsRegEx = /^(http[s]?:\/\/)/g
const emailRegEx = /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/gmi

const checkIfUrlHasHttps = (url:string) => {
  return url.search(hasHttpsRegEx) > -1
}

const autoAddHttps = (url:string) => {
  if (!checkIfUrlHasHttps(url)) {
    return `https://${url}`
  }
  return url
}

const getTextToLinkDiv = (text:string) => {
    let newHtmlText = text.replace(htmlRegEx,  (url)=>{
      const addedHttps = autoAddHttps(url)
      if (!checkIfUrlHasHttps(url)) console.info(`**** auto add https://. old: ${url}. new: ${addedHttps}`)
      const one = `${PREFIX_WHEN_IS_LINK}<a href=${addedHttps} target="_blank" rel="noopener noreferrer nofollow">${TEXT_WHEN_IS_LINK}</a>`;
      return one
    })
    newHtmlText = newHtmlText.replace(emailRegEx, (url) => {
      return `<a href="mailto:${url}">${url}</a>`
    })
    // console.debug(`newHtmlText`, newHtmlText)
    return <div
    dangerouslySetInnerHTML={{
      __html: newHtmlText,
    }}
  />
}

export default getTextToLinkDiv