import { useState } from "react";

const useFetchOrPost = (serviceFn) => {
  const [isLoading, setIsLoading] = useState(false);

  const callApi = async (params?, sessionId?:string) => {
    setIsLoading(true);
    return new Promise(async (resolve, reject) => {
      let paramsToUse 
      try {
        if (params && typeof(params) === 'object' && !Array.isArray(params)) {
          paramsToUse = params
        } else if (!params && sessionId) {
          paramsToUse = {}
        }
        if (sessionId) {
          paramsToUse.sessionId = sessionId
        }
        const data = await serviceFn(paramsToUse);
        if (data !== undefined) {
          resolve(data);
        }
        setIsLoading(false);
      } catch (error) {
        console.debug(`paramsToUse: `, paramsToUse, params)
        setIsLoading(false);
        reject(error);
      }
    });
  };

  return {
    isLoading,
    callApi,
  };
};

export default useFetchOrPost;
