import React, { useContext } from "react";
import styled from "styled-components";
import randomBg from "../../assets/images/random-bg-unsplash.jpg";
import { ChatPageContext } from "../../contexts/chat-page.contexts";

interface EachCardProps {
  data: any; // TODO: come back to this
  width?: string;
  optionsHeight?: string;
  maxOption?: number;
}
const EachCard: React.FC<EachCardProps> = ({
  data,
  width = "18rem",
  optionsHeight = "10rem",
}) => {
  const { handleSend } = useContext(ChatPageContext);
  return (
    <SCard key={data.title} width={width}>
      <STitle>
        <STitleText>{data.title}</STitleText>
        {data.subtitle && <SSubtitleText>{data.subtitle}</SSubtitleText>}
      </STitle>
      <SOptions height={optionsHeight}>
        {data.options.map((option) => (
          <SOption key={option.value} onClick={() => handleSend(option.value)}>
            <SOptionText>{option.label}</SOptionText>
            <i className="fas fa-chevron-right" />
          </SOption>
        ))}
      </SOptions>
    </SCard>
  );
};

export default EachCard;

const SCard = styled.div`
  margin-top: 5px;
  border-radius: ${(props) => `${props.theme.borderRadius.chatPanel}`};

  background: ${(props) => props.theme.palette.background.paper};
  //box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.25);
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.03);
  width: ${(props: { width: string }) => props.width};

  overflow: hidden;
  & > * {
    overflow: hidden; // to make it scrollable
  }
  & > * > * {
    width: calc(${(props: { width: string }) => props.width} - 1.5rem);
    white-space: nowrap; // to make it scrollable
    overflow: hidden; // to make it scrollable
    text-overflow: ellipsis;
  }
`;
const STitle = styled.div`
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover; /* Resize the background image to cover the entire container */
    background-color: ${props => props.theme.palette.secondary.dark};
    background-image: url(${randomBg});
    filter: brightness(50%);
    border-radius: ${(props) =>
      `${props.theme.borderRadius.chatPanel} ${props.theme.borderRadius.chatPanel} 0 0 `};
  }

  padding: 1rem;
`;
const SOptions = styled.div`
  overflow-y: scroll;
  height: ${(props: { height: string }) => props.height};

  //   part1: always show scroll bar
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    background-color: ${(props) => props.theme.palette.primary.darker};
  }
  //   part2: always show scroll bar
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${(props) => props.theme.palette.secondary.main};
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  border-radius: ${(props) =>
    `0 0 ${props.theme.borderRadius.chatPanel} ${props.theme.borderRadius.chatPanel}`};
`;

const SOption = styled.div`
  cursor: pointer; // set here so don't have to add classname to both the option at the data.option and the show more button
  padding: 1rem;
  background: ${(props) => props.theme.palette.background.paper};
  color: ${(props) => props.theme.palette.text.primary};
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  & i {
      margin-left: 0.5rem;
      margin-right: 1rem;
      color: ${(props) => props.theme.palette.primary.dark};
  }
  & + & {
    border-top: solid 1px ${(props) => props.theme.palette.common.borderGrey};
  }
  &:hover {
    background: ${(props) => props.theme.palette.background.default};
    transition: background 0.2s;
  }
  &:last-child {
  }
`;
const SSubtitleText = styled.div`
  margin-top: 5px;
  color: ${(props) => props.theme.palette.primary.contrastText};
  filter: grayscale(0);
  font-family: ${(props) => props.theme.typography.fontFamily.body};
  font-size: ${(props) => props.theme.typography.fontSize.extraSmall};
  font-weight: ${(props) => props.theme.typography.fontWeight.light};
  /* padding: 0 3px; */
  text-align: center;
  overflow-wrap: break-word;
  white-space: normal;
`;
const STitleText = styled.div`
  position: relative;
  /* padding: 1rem; */
  

  color: ${(props) => props.theme.palette.primary.contrastText};

  font-family: ${(props) => props.theme.typography.fontFamily.title};
  text-transform: uppercase;
  font-size: ${(props) => props.theme.typography.fontSize.medium};
  font-weight: ${(props) => props.theme.typography.fontWeight.bold};

  text-align: center;
  overflow-wrap: break-word;
  white-space: normal;
`;
const SOptionText = styled.div`
  overflow-wrap: break-word;
`;
