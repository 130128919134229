import React from "react";
import styled from "styled-components/macro";
import avatarImage from "../../assets/images/aia-ir-avatar.svg";

const AiaAvatar = ({ width = "40px", hiddenVisibility = false }) => {
  return (
    <SAiaAvatarImage src={avatarImage} width={width} height={width} hiddenVisibility={hiddenVisibility} />
  );
};

export default AiaAvatar;

const SAiaAvatarImage = styled.img`
  border-radius: 50%;
  /*box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25); */
  visibility: ${(props: {hiddenVisibility: boolean}) => props.hiddenVisibility ? 'hidden' : undefined};
`;
