import React, { useMemo, createContext } from "react";
import { ThemeProvider } from "styled-components";
import commonTheme from "./common";

interface ThemeProps {
  mode?: string;
}

const DEFAULT_MODE = "default";

export const ThemeContext = createContext({
  mode: DEFAULT_MODE,
});

const ThemeContextProvider: React.SFC<ThemeProps> = ({
  children,
  mode = DEFAULT_MODE,
}) => {
  const theme = useMemo(() => {
    return commonTheme;
  }, []);
  return (
    <ThemeContext.Provider value={{ mode }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
