import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import { ReactComponent as ChatBubbleIcon } from "../assets/icons/chat.svg";
import { ReactComponent as CloseIcon } from "../assets/icons/close.svg";
import { TogglePanelContext } from "../contexts/toggle-panel.contexts";
import ChatPage from "./ChatPage";
/**
 * for renderering in iframe on website
 */
const EmbeddedPage = () => {
  const { isOpen, sendPostMessage, handleToggleOpen } = useContext(TogglePanelContext)
  useEffect(() => {
    window.onload = () => sendPostMessage();
    return () => {
      window.onload = () => {};
    };
  }, []);

  return (
    <SRoot id="sroot">
      <SPanel className={`${isOpen ? "hide" : ""}`} id="ChatSpanel" >
        <ChatPage isEmbedded />
      </SPanel>
      <ChatIconButton id="ChatIcon"
        className={`clickable`}
        onClick={() => handleToggleOpen()}
      >
        {isOpen ? <SChatBubbleIcon /> : <SCloseIcon />}
      </ChatIconButton>
    </SRoot>
  );
};

export default EmbeddedPage;

const SChatBubbleIcon = styled(ChatBubbleIcon)`
  width: 32px;
  height: 29px;
  & path {
    fill: white;
  }
`;
const SCloseIcon = styled(ChatBubbleIcon)`
  width: 32px;
  height: 29px;
  & path {
    fill: ${(props) => props.theme.palette.common.greyred}; 
 }
`;

const ChatIconButton = styled.div`
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  background: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.common.white};
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.25);
  display:${(props)=> props.theme.borderRadius.buttonDisplay};
  align-items: center;
  justify-content: center;
  float: right;
  position:${(props) => `${props.theme.borderRadius.iPosition}`};
  right:${(props) => `${props.theme.borderRadius.iRight}`};
  bottom:${(props) => `${props.theme.borderRadius.iBottom}`};
  &:hover > * {
    transform: scale(1.1);
    transition: transform 0.1s linear;
  }
`;

const SRoot = styled.div`
  position: ${(props) => `${props.theme.borderRadius.sPosition}`};
  bottom: 0.5rem;
  right: 1rem;
  height: ${(props) => `${props.theme.borderRadius.SrootHeight}`};
  // NOTE: edit the zoom value here to be lower if the widget is too big
  zoom: 1;
`;

const SPanel = styled.div.attrs((props) => ({
  className: props.className,
}))`
  &.hide {
    display: none;
  }
  min-width: 320px;
  width: ${(props) => `${props.theme.borderRadius.windowWidth}`};
  height: ${(props) => `${props.theme.borderRadius.windowHeight}`};
  margin-bottom: 10px;
  border-radius: ${(props) => `${props.theme.borderRadius.chatPanel}`};
  box-shadow: ${(props) => props.theme.shadows[1]};
`;
