import React, { useEffect, useContext } from "react";
import { TogglePanelContext } from "../../contexts/toggle-panel.contexts";

export const isMobile= () =>{
  var regex_match = /(nokia|iphone|android|motorola|^mot-|softbank|foma|docomo|kddi|up.browser|up.link|htc|dopod|blazer|netfront|helio|hosin|huawei|novarra|CoolPad|webos|techfaith|palmsource|blackberry|alcatel|amoi|ktouch|nexian|samsung|^sam-|s[cg]h|^lge|ericsson|philips|sagem|wellcom|bunjalloo|maui|symbian|smartphone|midp|wap|phone|windows ce|iemobile|^spice|^bird|^zte-|longcos|pantech|gionee|^sie-|portalmmm|jigs browser|hiptop|^benq|haier|^lct|operas*mobi|opera*mini|320x320|240x320|176x220)/i;
    if (navigator.userAgent.match(regex_match)==null) {
      return false;
    }else
    return true;
 }
const sPosition= function(){
  if (isMobile()) {
    return "";      
    }else if (navigator.userAgent.match(/(pad|iPad)/i)){
      return window.orientation==90||window.orientation==-90 ?"absolute;":""
    }else 
    return "absolute;"
  };
const iPosition= function(){
  if (isMobile()) {
    return "absolute"      
    }else if (navigator.userAgent.match(/(pad|iPad)/i)){
      return window.orientation==90||window.orientation==-90 ?"":"absolute"
    }else 
    return ""
  };
   
const iBottom= function(){
  if (isMobile()) {
    return "0.5rem"      
    }else if (navigator.userAgent.match(/(pad|iPad)/i)){
      return window.orientation==90||window.orientation==-90 ?"":"0.5rem"
    }else 
    return ""
  };

const iRight= function(){
  if (isMobile()) {
    return "1rem"      
    }else if (navigator.userAgent.match(/(pad|iPad)/i)){
      return window.orientation==90||window.orientation==-90 ?"":"1rem"
    }else 
    return ""
  };

const ChatIconButton= () => {
 const { isOpen } = useContext(TogglePanelContext);
   if (isMobile()) {
    if (isOpen) {
      return "flex";
    }else
      return "none";     
    }
  else if (navigator.userAgent.match(/(pad|iPad)/i)){ 
      if (window.orientation==0||window.orientation==180 ) {
        if (isOpen) {
          return "flex";
        }else
          return "none"; 
      }else if (window.orientation==90||window.orientation==-90 ) {
        return "flex";
      }  
    }
  else 
    return "flex"
  };
const wWidth =function() {
  if (isMobile()) {
    return window.screen.width +"px" ;  
    }else if (navigator.userAgent.match(/(pad|iPad)/i)){
      return window.orientation==0||window.orientation==180 ?window.screen.width +"px" :"360px";
    }else 
    return "360px";
  };

const wHeight = function() {
   if (isMobile()) {
    return "100%";  

    }else if (navigator.userAgent.match(/(pad|iPad)/i)){
      return window.orientation==0||window.orientation==180 ?  "100%":"520px";
    }else 
    return "520px";
  };
const Sroot = function () {
  if (isMobile()) {
    return "100%";

  } else if (navigator.userAgent.match(/(pad|iPad)/i)) {
    return window.orientation == 0 || window.orientation == 180 ? "100%" : "520px";
  } else
    return null;
};

const buttonDisplay = function () {
  const getParamString =function(name){
    var reg= new RegExp("(^|&)" + name +"=([^&]*)(&|$)","i" );
    var r = window.location.search.substring(1).match(reg);
    if (r != null) {
      return decodeURIComponent(r[2]);
    }
    return null;
  }
  var pStr=getParamString("param")
  if (pStr!=null) {
    if (pStr.match("IRApp")) {
      return "none"
    }
  }else{
 return "flex"
 }
}


const common = {
  palette: {
    common: {
      black: "#000",
      white: "#fff",
      borderGrey: "#D5D8DA",
      greyred:"#e4708f"
    },
    primary: {
      light: "#EA7C7C", // not sure
      main: "#d31145",
      dark: "#D5174A",
      darker: "#D51545", 
      contrastText: "#fff",
    },
    secondary: {
      light: "#FFFFFF",// "#F0F0F0",
      main: "#EEEFF0",
      dark: "#333E47", // not sure // #a4a4a4 333E47
      contrastText: "rgb(71,71,71)", // not sure
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    warning: {
      light: "#ffb74d",
      main: "#ff9800",
      dark: "#ff9800",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    success: {
      light: "#81c784",
      main: "#4caf50",
      dark: "#388e3c",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    text: {
      primary: "rgb(71,71,71)",
      secondary: "rgb(185,185,185)",
      disabled: "rgba(0, 0, 0, 0.38)", // not sure
      hint: "rgba(0, 0, 0, 0.38)", // not sure
    },
    background: {
      paper: "#FFFFFF", // white for choice
      default: "#F7F7F8", //grey
    },
    grey: {
      50: "#fafafa",
      100: "#f5f5f5",
      200: "#eeeeee",
      300: "#e0e0e0",
      400: "#bdbdbd",
      500: "#9e9e9e",
      600: "#757575",
      700: "#616161",
      800: "#424242",
      900: "#212121",
      A100: "#d5d5d5",
      A200: "#aaaaaa",
      A400: "#303030",
      A700: "#616161",
    },
    divider: "rgba(0, 0, 0, 0.12)",
  },
  boxShadow: {
    appBar: "0px 3px 9px rgba(0, 0, 0, 0.25)",
  },
  shadows: [
    "none",
    "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
    "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
    "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
    "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
    "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
    "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
    "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
    "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
    "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
    "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
    "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
    "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
    "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
    "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
    "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
    "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
  ],
  typography: {
    fontFamily: {
      body: `"Open Sans", sans-serif`, //Prompt, sans-serif`,
      title: `"Open Sans", sans-serif`, //`Prompt, sans-serif`,
      smallText: `"Open Sans", sans-serif`, //`Roboto, sans-serif`, // not sure if needed
    },
    lineHeight: {
      body: 1.3,
      title: 1.5,
    },
    fontSize: {
      extraSmall: "10px",
      small: "12px",
      regular: "14px",
      medium: "16px",
      big: "20px",
    },
    fontWeight: {
      light: 300,
      regular: 400,
      medium: 600,
      bold: 700,
    },
  },
  borderRadius: {
    chatPanel: "12px",
    windowWidth: wWidth(),
    windowHeight: wHeight(),
    sPosition:sPosition(),
    iPosition:iPosition(),
    iBottom:iBottom(),
    iRight:iRight(),
    buttonDisplay:buttonDisplay(),
    SrootHeight:Sroot(),
  },
};

export default common;
