import React, { useEffect } from "react";
import "./App.css";
import ThemeContextProvider from "./contexts/theme/Theme";
import { initMoment } from "./utils/moment";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ChatPage from "./pages/ChatPage";
import EmbeddedPage from "./pages/EmbeddedPage";
import MockedWebsitePage from "./pages/MockedWebsitePage";
import TogglePanelContextProvider from "./contexts/toggle-panel.contexts";

const App: React.FC = () => {
  /**
   * init moment so can use moment.calendar to format createdAt time
   */
  useEffect(() => {
    initMoment();
  }, []);

  return (
    <ThemeContextProvider>
      <Router>
        <Switch>
          <Route exact path="/">
            <TogglePanelContextProvider><EmbeddedPage /></TogglePanelContextProvider>
          </Route>
          <Route exact path="/chat">
            <ChatPage />
          </Route>
          <Route exact path="/mocked-website">
            <MockedWebsitePage />
          </Route>
          <Route path="*" component={() => <div>Not found</div>} />
        </Switch>
      </Router>
    </ThemeContextProvider>
  );
};

export default App;
