import axios from "axios";

const params = {
  baseURL: process.env.REACT_APP_API_URL,
};
// global instance
var _instance = axios.create(params);

const getApiInstance = () => {
  return _instance;
};

export default getApiInstance;
