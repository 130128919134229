import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components/macro";
import Chat from "../../models/chat.models";
import EachCard from "./EachCard";

interface CardsProps {
  chatM: Chat;
}

const Cards: React.FC<CardsProps> = ({ chatM }) => {
  const cardsRef = useRef<any>(null);
  const eachCardRef = useRef<any[]>([]);
  const [viewingCardI, setViewingCardI] = useState(0);
  const nCards = useMemo(() => chatM.data.length, [chatM.data]);
  const scrollRight = () => {
    if (eachCardRef && eachCardRef.current && eachCardRef.current.length) {
      const newIndex = viewingCardI + 1;
      setViewingCardI(newIndex);
      _scrollIntoView(newIndex);
    }
  };

  /**
   * a little hacky
   * to scroll to the end vertically on init
   */
  useEffect(() => {
    _scrollIntoView(0);
  }, []);

  /**
   * has issue of unexpected vertical scroll. not sure how to fix yet.
   * @param index
   */
  const _scrollIntoView = (index: number) => {
    eachCardRef.current[index].scrollIntoView({       
      behavior: 'smooth',
      block: 'nearest',
    });
  };
  const scrollLeft = () => {
    if (eachCardRef && eachCardRef.current && eachCardRef.current.length) {
      const newIndex = viewingCardI - 1;
      setViewingCardI(newIndex);
      _scrollIntoView(newIndex);
    }
  };
  const shouldShowRightBtn = useMemo(() => viewingCardI < nCards - 1, [
    viewingCardI,
    nCards,
  ]);
  const shouldShowLeftBtn = useMemo(() => viewingCardI > 0, [viewingCardI]);
  return (
    <SCardsWrapper>
      {shouldShowLeftBtn && (
        <SScrollerLeft onClick={() => scrollLeft()}>
          <i className="fas fa-chevron-left"></i>
        </SScrollerLeft>
      )}
      {/* cardsRef is not used yet but maybe will */}
      <SCards ref={cardsRef}>
        {chatM.data.map((data, i) => {
          return (
            <SEachCardAndRef key={data.title}>
              <EachCard data={data} />
              <SCardRef
                ref={(ref) => {
                  eachCardRef.current[i] = ref;
                }}
              />
            </SEachCardAndRef>
          );
        })}
        {shouldShowRightBtn && (
          <SScrollerRight onClick={() => scrollRight()}>
            <i className="fas fa-chevron-right"></i>
          </SScrollerRight>
        )}
      </SCards>
    </SCardsWrapper>
  );
};

export default Cards;
const SEachCardAndRef = styled.div`
  display: flex;
`;
const SCardRef = styled.div``;
const SCardsWrapper = styled.div`
  position: relative;
  overflow: hidden;
  overflow-y: hidden;
`;
const SCards = styled.div`
  display: flex;
  align-items: baseline; // otherwise card inside will stretch
  margin-top: 0.3rem;
  margin-bottom: -0.7rem; // so the time looks aligned

  width: 390px; // to make it scrollable

  & > * + * {
    margin-left: 0.5rem;
  }
  & > * {
    display: inline-block;
    transform: translate3d(0px, 0px, 0px); // for mobile to work
  }
  ::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  overflow: hidden; // to make it scrollable
`;

const SScrollerRight = styled.div`
  position: absolute;
  width: 3rem;
  height: 3rem;
  right: 0;
  top: 40%;
  background: #0000006b;
  display: grid;
  place-items: center;
  border-radius: 50%;
  transform: translateX(1rem);
  cursor: pointer;
  & i {
    transform: translateX(-5px);
    color: white;
  }
`;
const SScrollerLeft = styled.div`
  position: absolute;
  width: 3rem;
  height: 3rem;
  left: 0;
  top: 40%;
  background: #0000006b;
  display: grid;
  place-items: center;
  border-radius: 50%;
  transform: translateX(-1rem);
  cursor: pointer;
  & i {
    transform: translateX(5px);
    color: white;
  }
  z-index: 1;
`;
