import React, { useEffect } from "react";
import styled from "styled-components";

/**
 * on scg website they will have to add this to the script tag
 */
const MockedWebsitePage = () => {
  useEffect(() => {
    window.onmessage = (e: any) => {
      if (e.data.hasOwnProperty("frameHeight")) {
        const iframe = document.getElementById("aia-chatbot-embedded-chat");
        if (iframe) {
          iframe.style.height = `${e.data.frameHeight + 30}px`;
          iframe.style.width = `${e.data.frameWidth + 30}px`;
        }
      }
    };
  }, []);
  return (
    <SSimpleWeb>
      <SMockedAppBar>Mocked App Bar</SMockedAppBar>
      <Sh1>Simple Website (z-index: 1010) </Sh1>
      <Iframe
        id="aia-chatbot-embedded-chat"
        src={`${window.location.origin}`}
      ></Iframe>
      <SMockedSnackbar>
        Mocked snackbar: (height: 88px, position: fixed, bottom: 8px) 
        <br />
        this website uses cookies for the purpose of enhancing your user experience. You can find more information on the types of cookies we collect, what we use these for, and how to manage your cookie settings in our Cookie Policy and Privacy Statement .</SMockedSnackbar>
    </SSimpleWeb>
  );
};

export default MockedWebsitePage;

const SSimpleWeb = styled.div`

  background: #FFFFFF;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Iframe = styled.iframe`
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 10px;
  margin-bottom: 100px; // v1.4.1 is 5rem
  border: none;
  z-index: 2000;
`;
const Sh1 = styled.h1`
  height: 80%;
  background: #FAF9F3;
  width: 100%;
  margin: 0 2vw;
  margin-bottom: 10vh;
  padding: 1rem;
`;
const SMockedSnackbar = styled.div`
  position: fixed;
  bottom: 8px;
  margin-top: 8px;
  left: 8px;
  right: 8px;
  z-index: 700;

  height: 88px;
  padding: 1rem;
  background-color: #363e3f;
  color: white;
  box-sizing: border-box;
`;

const SMockedAppBar = styled.div`
    z-index: 1010;
    background-color: #d31145;
    border-color: transparent;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    height: 88px;
    color: white;
    font-weight: bold;
    font-size: 20px;
    padding: 1rem;
    box-sizing: border-box;
`;