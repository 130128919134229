import moment from "../utils/moment";

const MSG_TYPES = [
  "TEXT",
  "OPTIONS",
  "CARDS",
  "TABLE_LINK",
  "LOADING",
  "ERROR",
] as const; // TS 3.4
type MsgTuple = typeof MSG_TYPES; // readonly ["TEXT", "OPTIONS", "CARDS", "TABLE_LINK", "ERROR"]
type MsgTypeI = MsgTuple[number]; // union type

export declare type MsgResArrayI = MsgResI[];

export interface LoadingMsgI extends MsgResI {
  type: "LOADING";
  data?: undefined;
}
export interface ErrorMsgI extends MsgResI {
  type: "ERROR";
  data?: undefined;
}
export interface MsgResI {
  type: MsgTypeI;
  data?: any;
}

export interface TextMsgResI extends MsgResI {
  type: "TEXT";
  data: string;
}

export interface OptionsMsgResI extends MsgResI {
  type: "OPTIONS";
  data: {
    title: string,
    subtitle?:string,
    options: string[] | { label: string; value: string }[]
  };
}

export interface OptionsMsgI extends MsgResI {
  type: "OPTIONS";
  data: {
    title?: string, // leave undefined or don't include it if don't want the header
    subtitle?: string, // leave undefined or don't include it if don't want the header
    options: { label: string; value: string }[]
  };
}

export interface CardsMsgResI extends MsgResI {
  type: "CARDS";
  data: {
    title: string;
    subtitle?: string;
    options: string[] | { label: string; value: string }[];
  }[];
}

export interface CardsMsgI extends MsgResI {
  type: "CARDS";
  data: {
    title: string;
    subtitle?: string;
    options: { label: string; value: string }[];
  }[];
}

export interface TableLinkMsgResI extends MsgResI {
  type: "TABLE_LINK";
  data: string; // html link -> click will open the link on the new tab
}

export declare type SenderRole = "USER" | "BOT";

interface ChatJson extends MsgResI {
  senderRole: SenderRole;
  createdAt: string;
}

class Chat {
  type: MsgTypeI;
  data;
  senderRole;
  createdAt;
  constructor(resData: MsgResI, senderRole: SenderRole) {
    const { type, data } = this.getTypeAndData(resData);
    this.type = type;
    this.data = data;
    this.senderRole = senderRole;
    this.createdAt = moment().valueOf();
  }
  isError() {
    return this.type === "ERROR";
  }
  isLoading() {
    return this.type === "LOADING";
  }
  isText() {
    return this.type === "TEXT" && typeof this.data === "string";
  }
  isCards() {
    return this.type === "CARDS";
  }
  isTableLink() {
    return this.type === "TABLE_LINK";
  }
  isOptions() {
    return this.type === "OPTIONS";
  }
  static addError(error: string) {
    return new Chat(
      {
        type: "ERROR",
        data: `${error}`,
      },
      "BOT"
    );
  }
  static createUserTextChat(text: string) {
    return Chat.createTextChat(text, "USER");
  }

  static createTextChat(text: string, senderRole: SenderRole) {
    return new Chat(
      {
        type: "TEXT",
        data: text,
      },
      senderRole
    );
  }
  isFromBot() {
    return this.senderRole === "BOT";
  }
  getTimeCalendar() {
    return moment(this.createdAt).calendar();
  }
  getTime() {
    return moment(this.createdAt).format("hh:mm A");
  }
  toJSON(): ChatJson {
    return {
      type: this.type,
      data: this.data,
      senderRole: this.senderRole,
      createdAt: this.createdAt,
    };
  }
  validateType(type: MsgTypeI) {
    if (!MSG_TYPES.includes(type)) {
      throw Error(`invalid type ${type}`);
    }
  }
  _convertToOptionsObjs(texts: any[]) {
    return texts.map((text) =>
      typeof text === "string" ? { value: text, label: text } : text
    );
  }
  getTypeAndData(resData) {
    const { type, data } = resData;
    const typeAndData = { type, data };
    this.validateType(type);
    switch (type) {
      case "TEXT":
        return typeAndData as TextMsgResI;
      case "OPTIONS":
        return {
          ...typeAndData,
          data: { ...data, options: this._convertToOptionsObjs(data.options) },
        } as OptionsMsgI;
      case "CARDS":
        return {
          ...typeAndData,
          data: data.map((cardD) => ({
            ...cardD,
            options: this._convertToOptionsObjs(cardD.options),
          })),
        } as CardsMsgI;
      case "TABLE_LINK":
        return typeAndData as TableLinkMsgResI;
      case "LOADING":
        return typeAndData as LoadingMsgI;
      case "ERROR":
        return typeAndData as ErrorMsgI;
      default:
        throw Error(`invalid type ${type}`);
    }
  }
}

export default Chat;
