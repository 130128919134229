import moment from "moment";

const LANG = "en";

export const initMoment = () => {
  moment.updateLocale(LANG, {
    calendar: {
      lastWeek: () => "[Last] dddd",
      lastDay: () => "[Yesterday]",
      sameDay: () => "[Today]",
    },
  });
};

export default moment;
