import React, { useContext, useState } from "react";
import styled from "styled-components/macro";
import AiaAvatar from "../conversations/AiaAvatar";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { TogglePanelContext } from "../../contexts/toggle-panel.contexts";
import avatarImage from "../../assets/images/aia-ir-avatar.svg";
import disclaimer from "../../configs/disclaimer";

const Disclaimer = () => {
  const [hasAccepted, setHasAccepted] = useState(false);
  const { handleToggleOpen } = useContext(TogglePanelContext);
  const handleClickClose = () => {
    handleToggleOpen();
  };
  const handleClickContinue = () => {
    setHasAccepted(true);
  };
  return (
    <SDisclaimer className={hasAccepted ? "close" : ""}>
      <SCloseButton className="clickable" onClick={() => handleClickClose()}>
        <SCloseIcon width="1.5rem" height="1.5rem" />
      </SCloseButton>
      <SAvatarWrapper>
        <SAiaAvatarImage src={avatarImage} width="80px" height="80px" />
      </SAvatarWrapper>
      <SContent>
        <SHeadAndBody>
          <SHeader><p>Hi! Welcome to AIA Investor Relations Chatbot.</p><p>Disclaimer and Terms of Use</p></SHeader>
          <SBody>{disclaimer}</SBody>
        </SHeadAndBody>
        <SButton className="clickable" onClick={() => handleClickContinue()}>
          I Agree
        </SButton>
      </SContent>
    </SDisclaimer>
  );
};

export default Disclaimer;

const SDisclaimer = styled.div.attrs((props) => ({
  className: props.className,
}))`
  position: absolute;
  top: 0;
  left: 0;
  height: ${(props) => `${props.theme.borderRadius.windowHeight}`}; 
  width: ${(props) => `${props.theme.borderRadius.windowWidth}`};
  background: ${(props) => props.theme.palette.primary.dark};
  &.close {
    display: none;
  }
  display: grid;
  line-height: 1.3;
  border-radius: ${(props) => `${props.theme.borderRadius.chatPanel}`};
  box-shadow: ${(props) => props.theme.boxShadow.appBar};
`;

const SHeader = styled.div`
  font-size: ${(props) => props.theme.typography.fontSize.medium};
  font-weight: ${(props) => props.theme.typography.fontWeight.bold};
  padding: 1rem 2rem 0 2rem;
  text-align: center;
`;

const SBody = styled.div`
  padding: 0 1.5rem;
  margin-bottom: 0.5rem;
`;
const SAvatarWrapper = styled.div`
  text-align: center;
  z-index: 1;
  margin-top: 1.8rem;
`;
const SCloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  margin: 0.5rem;
  z-index: 2;
  display:${(props)=> props.theme.borderRadius.buttonDisplay};
`;
const SContent = styled.div`
  background: ${(props) => props.theme.palette.background.default};
  border-radius: ${(props) =>
    `0 0 ${props.theme.borderRadius.chatPanel} ${props.theme.borderRadius.chatPanel}`};
  display: grid;
  grid-template-rows: 1fr auto;
  &::after {
    content: "";
    height: 2.52rem;
    width: 100%;
    border-radius: 50% 50% 0 0;
    background: ${(props) => props.theme.palette.background.default};
    position: absolute;
    top: 4.6rem;
  }
  overflow: hidden;
`;
const SButton = styled.div`
  padding: 1rem;
  margin: 10px;
  text-align: center;
  background: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.primary.contrastText};
  border-radius: 5px;
  font-weight: ${(props) => props.theme.typography.fontWeight.medium};
  font-size: ${(props) => props.theme.typography.fontSize.medium};
  &:hover {
    background: ${(props) => props.theme.palette.primary.dark};
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.25);
  }
`;
const SAiaAvatarImage = styled.img`
  border-radius: 50%;
  box-shadow: 0px -1px 3px 2px rgb(255 255 255 / 61%);
`;
const SCloseIcon = styled(CloseIcon)`
  & > path {
    fill: ${(props) => props.theme.palette.primary.contrastText};
  }
`;
const SHeadAndBody = styled.div`
  overflow: auto;
`;
