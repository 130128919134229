const disclaimer = (
  <div>
    <p>
     Due to legal restrictions, your use of the AIA Investor Relations Chatbot (the “Chatbot”) is subject to acceptance by you of the following terms:
    </p>
    <p>
     The information provided by the Chatbot and the websites accessed through the Chatbot (the “Information”) does not constitute or form part of, and should not be construed as, an offer to sell or issue or the solicitation of an offer to buy or acquire securities of AIA Group Limited (the “Company”) or any of its subsidiaries (together, “AIA Group”) in any jurisdiction or an inducement to enter into investment activity. No part of the Information, nor the fact of its distribution, shall form the basis of or be relied upon in connection with any contract or commitment whatsoever. No securities of the Company may be sold in the United States or to U.S. persons except pursuant to an exemption from, or in a transaction not subject to, the registration requirements of the U.S. Securities Act of 1933, as amended. In Hong Kong, no shares of the Company may be offered by the Company to the public unless a prospectus in connection with the offering for sale or subscription of such shares has been authorised by The Stock Exchange of Hong Kong Limited for registration by the Registrar of Companies under the provisions of the Companies Ordinance, and has been so registered.
    </p>
    <p>
     The Information is given to you solely for your own use and information, and no part of this Information may be copied or reproduced, or redistributed or passed on, directly or indirectly, to any other person in any manner or published, in whole or in part, for any purpose. The distribution of the Information may be restricted by law, and persons into whose possession this Information comes should inform themselves about, and observe, any such restrictions. Each person accessing the Information or using the Chatbot confirms that they are a person who is entitled to do so under all applicable laws, regulations and directives in all applicable jurisdictions.
    </p>
    <p>
     The Information was current as at the dates stated. The Information is not to be relied upon as being accurate, complete or up to date, and is subject to change without notice. The Information is not to be used for any purpose other than providing factual historical data and it is not to be construed as an indication or prediction of future results or performance.
    </p>
    <p>
     None of the members of AIA Group warrant the fairness, accuracy, adequacy or completeness of the Information. To the maximum extent permitted by law, neither AIA Group nor its directors, employees, agents or advisers accepts any liability whatsoever for any loss (including, without limitation, any liability arising from any fault or negligence on the part of AIA Group or its respective directors, employees, agents or advisers) arising from the use or access of the Information and/or the Chatbot or otherwise arising in connection with the Information and/or the Chatbot.
    </p>
    <p>
     The Information may contain certain forward-looking statements relating to AIA Group that are based on the beliefs of the management of AIA Group as well as assumptions made by and information currently available to the management of AIA Group. These forward-looking statements are, by their nature, subject to significant risks and uncertainties. When used in the Information, the words “anticipate”, “believe”, “could”, “estimate”, “expect”, “going forward”, “intend”, “may”, “ought” and similar expressions, as they relate to AIA Group or the management of AIA Group, are intended to identify forward-looking statements. These forward-looking statements reflect the views of AIA Group only as of the date stated and are not a guarantee of future performance or developments. You are strongly cautioned that reliance on any forward-looking statements involves known and unknown risks and uncertainties. Actual results and events may differ materially from information contained in the forward-looking statements. AIA Group assumes no obligation to update or otherwise revise these forward-looking statements for new information, events or circumstances that occur subsequent to such dates.
    </p>
    <p>
     The Information does not constitute investment advice or an investment recommendation. Should you have any doubts about the meaning of the Information, please contact your financial advisor or any other independent professional advisor.
    </p>
    <p>
     You should not input any personal or confidential information into the Chatbot. The Chatbot will not ask you any questions relating to your personal details. You agree that your conversation may be stored and may be reviewed by AIA Group, its affiliates and/or its authorised third parties for the purposes of data analytics and quality enhancement purposes. You acknowledge that all information you provide to the Chatbot is governed by our Privacy Statement and it is available on our website at https://www.aia.com/en/index/privacy-statement.html.
    </p>
  </div>
);

export default disclaimer;
