import React, { useState, useMemo, useContext } from "react";
import styled from "styled-components/macro";
import { ChatPageContext } from "../../contexts/chat-page.contexts";
import Chat from "../../models/chat.models";

interface OptionsCardProps {
  chatM: Chat; // TODO: come back to this
  width?: string;
  height?: string;
  maxOption?: number;
}
const OptionsCard: React.FC<OptionsCardProps> = ({
  chatM,
  width = "226px", // width of the opitons card
  maxOption = 3,
}) => {
  const { handleSend } = useContext(ChatPageContext);
  const data = useMemo(() => chatM.data, [chatM]);
  const [isShowMore, setIsShowMore] = useState(false);
  const optionsToShow = useMemo(
    () => (isShowMore ? data?.options : data?.options.slice(0, maxOption)),
    [data?.options, isShowMore, maxOption]
  );
  const showToggleShowMoreButton = useMemo(
    () => data?.options.length > maxOption,
    [data?.options, maxOption]
  );
  const handleToggleShowMore = () => {
    setIsShowMore(!isShowMore);
  };

  const hasTitle = useMemo(()=>{ return !!(data.title || data.subtitle) } , [data])
  return (
    <SCard key={data.title} width={width}>
      { hasTitle && <STitle>
        <STitleText>{data.title}</STitleText>
        {data.subtitle  && <SSubTitleText>
          { data.subtitle 
          // || 'Please type your question or select quick answer below'
          }
        </SSubTitleText>}
      </STitle>}
      <SOptions className={!hasTitle ? 'without-title': ''}>
        {optionsToShow.map((option) => (
          <SOption key={option.value} onClick={() => handleSend(option.value)}>
            <SOptionText>{option.label}</SOptionText>
            <i className="fas fa-chevron-right" />
          </SOption>
        ))}
        {showToggleShowMoreButton && (
          <SOption
            onClick={() => handleToggleShowMore()}
            className={"is-showmore-btn"}
          >
            <SOptionText>
              {isShowMore ? "See Less" : "See More"}
              {isShowMore ? (
                <i className="fas fa-chevron-up" />
              ) : (
                <i className="fas fa-chevron-down" />
              )}
            </SOptionText>
          </SOption>
        )}
      </SOptions>
    </SCard>
  );
};

export default OptionsCard;

const SCard = styled.div`
  border-radius: ${(props) => `${props.theme.borderRadius.chatPanel}`};

  background: ${(props) => props.theme.palette.background.paper};
  /*box-shadow: 2px 2px 5px  rgba(0, 0, 0, 0.25);*/
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.03);
  width: ${(props: { width: string }) => props.width};
  /* & > * {
    white-space: nowrap; // to make it scrollable
    overflow: hidden; // to make it scrollable
    text-overflow: ellipsis;
  } */
`;
const STitle = styled.div`
  background: ${(props) => props.theme.palette.secondary.light};
  //color: ${(props) => props.theme.palette.primary.contrastText};
  color: #333d47;
  border-radius: ${(props) =>
    `0 ${props.theme.borderRadius.chatPanel} 0 0`};
  text-align: left;
  border-bottom: solid 1px ${(props) => props.theme.palette.common.borderGrey};
  display: grid;
  padding: 11px 14px 17px 16px;
  
`;
const SOptions = styled.div.attrs(props=>({ className: props.className }))`
  &.without-title {
    & > *:first-child {
      border-radius: ${(props) =>
        `0 ${props.theme.borderRadius.chatPanel} 0 0`};
    }
  }
`;
const STitleText = styled.div`
  font-family: ${(props) => props.theme.typography.fontFamily.title};
  font-size: ${(props) => props.theme.typography.fontSize.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.medium};
  padding: 0 3px;
  overflow-wrap: break-word;
  white-space: normal;
  display: inline;
  line-height: 20px;
`;
const SSubTitleText = styled.div`
  margin-top: 0.5rem;
  font-family: ${(props) => props.theme.typography.fontFamily.body};
  font-size: ${(props) => props.theme.typography.fontSize.small};
  font-weight: ${(props) => props.theme.typography.fontWeight.light};
  padding: 0 3px;
  overflow-wrap: break-word;
  white-space: normal;
  display: inline;
  line-height: 16px;
`;
const SOption = styled.div.attrs((props) => ({
  className: props.className,
}))`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  & i {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      color: ${(props) => props.theme.palette.primary.dark};
  }
  &.is-showmore-btn {
    background: ${(props) => props.theme.palette.background.paper};

    text-align: center;
    & i {
      margin-left: 1rem;
    }
  }
  cursor: pointer; // set here so don't have to add classname to both the option at the data.option and the show more button
  /* padding: 1rem; */
  padding: 14px;
  background: ${(props) => props.theme.palette.background.paper};
  color: ${(props) => props.theme.palette.text.primary};
  & + & {
    border-top: solid 1px ${(props) => props.theme.palette.common.borderGrey};
  }
  &:hover {
    /* background: ${(props) => props.theme.palette.primary.darker}; */
    background: ${(props) => props.theme.palette.background.default};
    transition: background 0.2s;
  }
  &:last-child {
    border-radius: ${(props) =>
      `0 0 ${props.theme.borderRadius.chatPanel} ${props.theme.borderRadius.chatPanel}`};
  }
`;

const SOptionText = styled.div`
  overflow-wrap: break-word;
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
`;
