import React, { useContext, useEffect, useMemo } from "react";
import styled from "styled-components/macro";
import Disclaimer from "../components/chat-panels/Disclaimer";
import InputBar from "../components/chat-panels/InputBar";
import ChatEl from "../components/conversations/ChatEl";
import {
  ChatPageContext,
  ChatPageContextProvider,
} from "../contexts/chat-page.contexts";
import Chat from "../models/chat.models";
import { ReactComponent as CloseIcon } from "../assets/icons/close.svg";
import { TogglePanelContext } from "../contexts/toggle-panel.contexts";

interface ChatPageProps {
  isEmbedded?: boolean;
}
const ChatPage: React.FC<ChatPageProps> = ({ isEmbedded = false }) => {
  const { handleToggleOpen } = useContext(TogglePanelContext);
  const handleClickClose = () => {
    handleToggleOpen();
  };
  const {
    chatMs,
    handleInit,
    hasAnyLoading,
    handleSend,
    messagesEndRef,
  } = useContext(ChatPageContext);
  useEffect(() => {
     
    handleInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const loadingM = useMemo(() => new Chat({ type: "LOADING" }, "BOT"), []);

  return (
    <SChatPage>
      <SHeader>AIA Investor Relations Chatbot
      <SCloseButton className="clickable" onClick={() => handleClickClose()}>
        <SCloseIcon width="1.5rem" height="1.5rem" />
        </SCloseButton>
      </SHeader>
      <SChatContent>
        {chatMs.map((chatM, i) => {
          const hideAvatar = i === 0 ? false : chatMs[i - 1].isFromBot();
          return (
            <ChatEl key={`chatM-${i}`} chatM={chatM} hideAvatar={hideAvatar} 
            />
          );
        })}
        {hasAnyLoading && <ChatEl chatM={loadingM} />}
        <div ref={messagesEndRef} />
      </SChatContent>
      <InputBar handleSend={handleSend} />
      <Disclaimer />
    </SChatPage>
  );
};

const ChatPageContainer: React.FC<ChatPageProps> = (props) => {
  return (
    <ChatPageContextProvider>
      <ChatPage {...props} />
    </ChatPageContextProvider>
  );
};

export default ChatPageContainer;

const SChatPage = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  font-family: ${(props) => props.theme.typography.fontFamily.body};
  font-size: ${(props) => props.theme.typography.fontSize.regular};
  color: ${(props) => props.theme.palette.text.primary};
`;
const SHeader = styled.div`
  background: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.primary.contrastText};
  box-shadow: ${(props) => props.theme.boxShadow.appBar};
  height: 3rem;
  display: flex;
  align-items: center;
  padding: 0.2rem 1rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.bold};
  font-size: ${(props) => props.theme.typography.fontSize.medium};
  border-radius: ${(props) =>
    `${props.theme.borderRadius.chatPanel} ${props.theme.borderRadius.chatPanel} 0 0`};
  font-family: ${(props) => props.theme.typography.fontFamily.title};
  /* letter-spacing: 0.1px; */
`;
const SChatContent = styled.div`
  padding: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  background: ${(props) => props.theme.palette.background.default};
`;

const SCloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  margin: 0.5rem;
  z-index: 2;
  display:${(props)=> props.theme.borderRadius.buttonDisplay};
`;
const SCloseIcon = styled(CloseIcon)`
  & > path {
    fill: ${(props) => props.theme.palette.primary.contrastText};
  }
`;
