import React, { createContext, useEffect, useMemo, useRef, useState } from "react";
import useFetchOrPost from "../custom-hooks/useFetchOrPost";
import Chat, { MsgResArrayI } from "../models/chat.models";
import dispatcherServices from "../services/dispatcher.services";
import generateSessionId from "../utils/generateSessionId";

export const ChatPageContext = createContext({
  chatMs: [] as Chat[],
  handleInit: () => {},
  handleSend: async (text: string) => {},
  hasAnyLoading: false,
  messagesEndRef: null as any,
  scrollToBottom: () => {},
});



interface ChatPageContextProviderProps {}

export const ChatPageContextProvider: React.FC<ChatPageContextProviderProps> = ({
  children,
}) => {
  const [chatMs, setChatMs] = useState<Chat[]>([]);

  const sessionIdRef = useRef(generateSessionId())

  useEffect(() => {
    console.info(`**** sessionId: ${sessionIdRef.current} ****`)
  }, [sessionIdRef])


  const { isLoading: initIsLoading, callApi: callInitApi } = useFetchOrPost(
    dispatcherServices.init
  );

  const {
    isLoading: postTextIsLoading,
    callApi: callPostTextApi,
  } = useFetchOrPost(dispatcherServices.postText);

  const handleInit = async () => {
    try {
      const data = (await callInitApi(undefined, sessionIdRef.current)) as MsgResArrayI;
      if (data) {
        setChatMs(data.map((d) => new Chat(d, "BOT")));
      }
    } catch (error) {
      console.error(error);
      appendChatMs([Chat.addError(error)]);
    }
  };
  const handleSend = async (text: string) => {
    appendChatMs([Chat.createUserTextChat(text)]);
    try {
      const data = (await callPostTextApi({ text }, sessionIdRef.current)) as MsgResArrayI;
      if (data) {
        appendChatMs(data.map((d) => new Chat(d, "BOT")));
      }
    } catch (error) {
      console.error(error);
      appendChatMs([Chat.addError(error)]);
    }
  };

  const appendChatMs = async (newChatMs: Chat[]) => {
    await setChatMs((chatMs) => [...chatMs, ...newChatMs]);
    scrollToBottom();
  };

  const hasAnyLoading = useMemo(() => initIsLoading || postTextIsLoading, [
    initIsLoading,
    postTextIsLoading,
  ]);

  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    if (messagesEndRef && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  
  return (
    <ChatPageContext.Provider
      value={{
        chatMs,
        handleInit,
        handleSend,
        hasAnyLoading,
        messagesEndRef,
        scrollToBottom,
      }}
    >
      {children}
    </ChatPageContext.Provider>
  );
};
